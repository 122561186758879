import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { type QuoteState, quoteStoreCreator } from "./quote-store-creator";

/** Storage key, exported for testing purposes */
export const QUOTE_STORE_KEY = "kozien/quote";

export const useQuoteStore = create<QuoteState>()(
    persist(quoteStoreCreator, {
        name: QUOTE_STORE_KEY,
        storage: createJSONStorage(() => localStorage),
    }),
);
