import { createStore, type StoreApi } from "zustand";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { createLogger } from "@/lib/logger";

const logger = createLogger("store:features");

import type { Feature, PartialFeature } from "./Feature";
import type { PlasticProfileFeature } from "./PlasticProfileFeature";
import type { NumericFeature } from "./NumericFeature";
import type { GlassFeature } from "./GlassFeature";
import type { ColorFeature } from "./ColorFeature";
import type { DropdownFeature } from "./DropdownFeature";
import type { PaneArrangementFeature } from "./PaneArrangementFeature";
import type { HandleFeature } from "./HandleFeature";
import type { BooleanFeature } from "./BooleanFeature";
import { createContext, useContext } from "react";
import { isNumericFeature } from "@/store/features/isNumericFeature";

export type { Feature, PartialFeature };
export type { PlasticProfileFeature };
export type { NumericFeature };
export type { GlassFeature };
export type { ColorFeature };
export type { DropdownFeature };
export type { HandleFeature };
export type { BooleanFeature };

interface FeaturesProps {
    features: Feature[];
    lastValidFeatures: Feature[];
    isValid: boolean;
    errors: Record<string, boolean>;
    touched: Record<string, boolean>;
    preventReset?: boolean;
}

type CreateFeatureProps = {
    key: Feature["key"];
    type: Feature["type"];
} & PartialFeature;

type CreateFeatureFn = (props: CreateFeatureProps) => Feature;

type GetFeatureByKeyFn = (key: Feature["key"]) => Feature | undefined;

type UpdateFeatureFn = (
    feature: Feature,
    isValid: boolean,
    isTouched: boolean,
) => void;

type InitialReturnFn = (feature: Feature[]) => void;

interface FeaturesState extends FeaturesProps {
    hasError: (key: Feature["key"]) => boolean;
    isTouched: (key: Feature["key"]) => boolean;
    isPristine: (key: Feature["key"]) => boolean;
    reset: () => void;
    create: CreateFeatureFn;
    getByKey: GetFeatureByKeyFn;
    update: UpdateFeatureFn;
    initialReturn: InitialReturnFn;
    setPreventReset: (preventReset: boolean) => void;
    resetTouched: (key: Feature["key"]) => void;
}

type FeaturesStore = StoreApi<FeaturesState>;

export const createFeaturesStore = (initialProps?: Partial<FeaturesProps>) => {
    const DEFAULT_PROPS = {
        features: [],
        lastValidFeatures: [],
        errors: {},
        touched: {},
        isValid: true,
        preventReset: false,
    } satisfies Partial<FeaturesProps>;

    return createStore<FeaturesState>()(
        devtools((set, get) => {
            return {
                ...DEFAULT_PROPS,
                ...initialProps,

                reset: () => {
                    if (get().preventReset) {
                        logger.verbose("resetting features store prevented");
                        return;
                    }

                    const prevState = get();
                    const nextState = {
                        ...DEFAULT_PROPS,
                        ...initialProps,
                    };

                    logger.verbose("resetting features store", {
                        prevState,
                        nextState,
                    });

                    return set(() => nextState, false, "features/reset");
                },

                setPreventReset: (preventReset) => {
                    logger.verbose("setPreventReset", preventReset);
                    set({ preventReset }, false, "features/setPreventReset");
                },

                hasError: (key) => {
                    return get().errors[key] ?? false;
                },

                isTouched: (key) => {
                    return get().touched[key] ?? false;
                },

                isPristine: (key) => {
                    return !get().touched[key];
                },

                create: ({ key, type, ...rest }) => {
                    const existingFeature = get().features.find(
                        (feature) => feature.key === key,
                    );

                    if (existingFeature) {
                        return existingFeature;
                    }

                    const feature = {
                        key,
                        type,
                        ...rest,
                    } as Feature;

                    set(
                        (state) => ({
                            features: [...state.features, feature],
                        }),
                        false,
                        "features/create",
                    );

                    return feature;
                },

                initialReturn: (features) => {
                    set(
                        (state) => ({
                            features: features,
                            lastValidFeatures: features,
                            errors: {},
                            touched: state.touched,
                            isValid: true,
                        }),
                        false,
                        "features/initialReturn",
                    );
                },

                getByKey: function getFeatureByKeyFn(key) {
                    return get().features.find(
                        (feature) => feature.key === key,
                    );
                },

                update: (updatedFeature, isValid, isTouched) => {
                    set(
                        (state) => {
                            if (updatedFeature.key == null)
                                throw new Error("key is null");

                            const updatedFeatures = state.features.map(
                                (currentFeature) =>
                                    currentFeature.key === updatedFeature.key
                                        ? updatedFeature
                                        : currentFeature,
                            );

                            const updatedLastValidFeatures = isValid
                                ? updatedFeatures
                                : state.lastValidFeatures;

                            const updatedErrors = {
                                ...state.errors,
                                [updatedFeature.key]: !isValid,
                            };

                            const updatedTouched = {
                                ...state.touched,
                                [updatedFeature.key]: isTouched,
                            };

                            const updatedIsValid = Object.values(
                                updatedErrors,
                            ).every((error) => !error);

                            const updates = {
                                features: updatedFeatures,
                                lastValidFeatures: updatedLastValidFeatures,
                                errors: updatedErrors,
                                isValid: updatedIsValid,
                                touched: updatedTouched,
                            } satisfies Partial<FeaturesProps>;

                            return updates;
                        },
                        false,
                        "features/update",
                    );
                },
                resetTouched: (key) => {
                    set(
                        (state) => {
                            const updatedTouched = {
                                ...state.touched,
                                [key]: false,
                            };

                            return {
                                touched: updatedTouched,
                            };
                        },
                        false,
                        "features/resetFeatureTouch",
                    );
                },
            };
        }),
    );
};

//
// Context
// =============================================================================
// This is a React Context that allows us to use the store in a React component
// without having to pass it down through props. It's a bit like Redux's
// Provider component.

export const FeaturesContext = createContext<FeaturesStore | null>(null);

export const useFeaturesStore = <U>(
    selector: (state: FeaturesState) => U,
): U => {
    const featuresStore = useContext(FeaturesContext);

    if (!featuresStore) {
        throw new Error(
            "useFeaturesStore must be used within a FeaturesContext.Provider.",
        );
    }

    return useStoreWithEqualityFn(featuresStore, selector);
};

//
// Value Selectors
// =============================================================================

export const getNumericInputFeatureByKey =
    (key: string) => (state: FeaturesState) =>
        state.features
            .filter(isNumericFeature)
            .find((feature) => feature.key === key);

export const getWidth = (features: Feature[]) => {
    const widthFeature = features.find(
        (feature) => feature.key === "width",
    ) as NumericFeature;

    return widthFeature?.value ? Number(widthFeature?.value) : undefined;
};

export const getHeight = (features: Feature[]) => {
    const heightFeature = features.find(
        (feature) => feature.key === "height",
    ) as NumericFeature;

    return heightFeature?.value ? Number(heightFeature?.value) : undefined;
};

export const getInnerColor = (features: Feature[]) => {
    const innerColorFeature = features.find(
        (feature) => feature.key === "innerColor",
    ) as ColorFeature;

    return innerColorFeature?.value ?? "";
};

export const getOuterColor = (features: Feature[]) => {
    const outerColorFeature = features.find(
        (feature) => feature.key === "outerColor",
    ) as ColorFeature;

    return outerColorFeature?.value ?? "";
};

export const getTurningPartsColor = (features: Feature[]) => {
    const turningPartsColorFeature = features.find(
        (feature) => feature.key === "turningPartsColor",
    ) as ColorFeature;

    return turningPartsColorFeature?.value ?? "";
};

export const getPaneArrangement = (features: Feature[]) => {
    const paneArrangementFeature = features.find(
        (feature) => feature.key === "paneArrangement",
    ) as PaneArrangementFeature;

    return paneArrangementFeature;
};

export const getVentilationGrille = (features: Feature[]) => {
    const ventilationGrille = features.find(
        (feature) => feature.key === "ventilationGrille",
    ) as DropdownFeature;

    return ventilationGrille;
};

export const getDoorType = (features: Feature[]) => {
    const doorType = features.find(
        (feature) => feature.key === "doorType",
    ) as DropdownFeature;

    return doorType;
};

export const getBreatworkType = (features: Feature[]) => {
    const breastworkType = features.find(
        (feature) => feature.key === "breastwork",
    ) as DropdownFeature;

    return breastworkType;
};
